.container {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .content {
    z-index: 10;
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .matrixBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  .matrixColumn {
    position: absolute;
    top: -50%;
    width: 1px;
    height: 200%;
    background-image: linear-gradient(
      0deg,
      rgba(0, 255, 65, 0) 0%,
      rgba(0, 255, 65, 1) 50%,
      rgba(0, 255, 65, 0) 100%
    );
    animation: fall 15s infinite linear;
  }
  
  .matrixColumn span {
    display: block;
    opacity: 0;
    animation: appear 0.5s infinite steps(1);
  }
  
  .retroText {
    text-shadow: 0 0 5px #00ff41, 0 0 10px #00ff41, 0 0 15px #00ff41;
    animation: flicker 0.5s infinite alternate;
  }
  
  .animatedText {
    color: #00ff41;
    font-family: monospace;
    font-size: 2rem;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .animatedText {
      font-size: 2.25rem;
    }
  }
  
  .animatedButton {
    background-color: #00ff41;
    color: black;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-family: monospace;
    font-size: 1.25rem;
    margin-top: 2rem;
    transition: background-color 0.3s;
    animation: bounce 1s infinite;
  }
  
  .animatedButton:hover {
    background-color: #00cc33;
  }

  .error {
    color: #ff4141;
    font-family: monospace;
    margin-top: 1rem;
    text-align: center;
  }
  
  .apiResponse {
    color: #00ff41;
    font-family: monospace;
    margin-top: 1rem;
    text-align: center;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .animatedButton:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  @keyframes fall {
    to { transform: translateY(100%); }
  }
  
  @keyframes appear {
    50% { opacity: 1; }
  }
  
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.8; }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }



  /* .dailyContainer {
    width: 90%;
    height: 83%;
    max-width: 1050px;
    max-height: 600px;
    margin-top: 1rem;
  } */
  
  /* Adjust the container to accommodate the Daily room */
  /* .container {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .content {
    z-index: 10;
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 100%;
  } */





  .roomContainer {
    display: flex;
    width: 100%;
    height: 83%;
    /* max-width: 1650px; */
    max-height: 600px;
    margin-top: 1rem;
  }
  
  .dailyContainer {
    flex: 1;
    height: 100%;
    margin-right: 1rem;
  }
  
  .websiteView {
    flex: 1;
    height: 100%;
    border: none;
    border-radius: 12px;
  }
  
  /* Adjust the container to accommodate the Daily room and website view */
  .container {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .content {
    z-index: 10;
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 100%;
  }